html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #c770f0;
  --imp-text-color2: #d28cea;
  --imp-text-color3: #5c0f7f;
}

.link-without-styling {
  color: inherit;
  text-decoration: inherit;
}

.link-without-styling:hover {
  color: inherit;
  text-decoration: inherit;
}

.a {
  cursor: pointer;
}

.purple {
  color: var(--imp-text-color) !important;
}

.purple:hover {
  background: linear-gradient(
    var(--imp-text-color) 0%,
    var(--imp-text-color3) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.read-more {
  color: var(--imp-text-color) !important;
  cursor: pointer;
  text-decoration: underline;
}

button:focus {
  box-shadow: none !important;
}

.normal-button {
  background-color: var(--imp-text-color) !important;
  color: white !important;
}

.normal-button:hover {
  background-color: var(--imp-text-color2) !important;
  color: white !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0 10px 10px 0 rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.nav-item {
  transition: opacity 0.2s ease-in-out;
  opacity: 0.2;
}

.nav-item:hover {
  opacity: 1;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #be50f4 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.link {
  color: var(--imp-text-color) !important;
  font-weight: 800 !important;
  display: inline-block !important;
}

.link2 {
  color: var(--imp-text-color) !important;
  font-weight: 800 !important;
}

.link:hover {
  color: var(--imp-text-color2) !important;
  font-weight: 800 !important;
  text-decoration: underline;
  text-decoration-thickness: 4px;
  text-decoration-color: var(--imp-text-color3);
  text-decoration-skip-ink: auto;
  text-underline-position: under;
  transition: color 600ms ease;
}

.link2:hover {
  color: var(--imp-text-color2) !important;
  font-weight: 800 !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #c95bf5;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

.point {
  animation-name: pointer;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes pointer {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  background-image: var(--image-gradient), url(Assets/home-bg.webp);
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  z-index: 0;
  mask-image: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.9), black);
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #cd5ff8;

  &:hover {
    cursor: pointer;

    .main-name-letter1 {
      transform: translate(-80%, 60%) rotate(8deg);
    }

    .main-name-letter2 {
      transform: translate(-40%, 20%) rotate(4deg);
    }

    .main-name-letter3 {
      transform: translate(-10%, 60%) rotate(-6deg);
    }

    .main-name-letter4 {
      transform: translate(0%, 8%) rotate(-8deg);
    }

    .main-name-letter5 {
      transform: translate(0%, -20%) rotate(5deg);
    }

    .main-name-letter7 {
      transform: translate(0%, 20%) rotate(-3deg);
    }

    .main-name-letter8 {
      transform: translate(0%, -40%) rotate(-5deg);
    }

    .main-name-letter9 {
      transform: translate(0%, 15%) rotate(10deg);
    }

    .main-name-letter10 {
      transform: translate(0%, -50%) rotate(8deg);
    }

    .main-name-letter11 {
      transform: translate(0%, 15%) rotate(-6deg);
    }

    .main-name-letter12 {
      transform: translate(50%, -10%) rotate(-3deg);
    }

    .main-name-letter13 {
      transform: translate(120%, -30%) rotate(-10deg);
    }
  }
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}

.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #b562d6 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvatar {
  justify-content: center !important;
  horiz-align: center !important;
  padding-top: 9em !important;
  height: 75% !important;
}

.previewAbout {
  justify-content: center !important;
  padding-top: 9em !important;
}

.previewAbout {
  justify-content: center !important;
  padding-top: 9em !important;
}

.previewProjects {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvatar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }

  .previewAbout {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }

  .previewProjects {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-summary-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  color: white !important;
}

.home-projects-summary-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  color: white !important;
}

.about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  color: white !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-summary-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-projects-summary-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.demo-button {
  margin-left: 10px;
}

.demo-button-special {
  margin-left: 10px;
}

.download-button {
  margin-left: 10px;
}

.download-button-special {
  margin-left: 10px;
}

@media (max-width: 1024px) and (min-width: 400px) {
  .download-button {
    margin-top: 10px;
  }
  .download-button-special {
    margin-top: 10px;
  }
  .demo-button-special {
    margin-top: 10px;
  }
}

@media (min-width: 362px) and (max-width: 767px) {
  .download-button-special {
    margin-top: 0;
  }
  .demo-button-special {
    margin-top: 0;
  }
}

@media (max-width: 351px) {
  .download-button-special {
    margin-top: 10px;
  }
  .demo-button-special {
    @media (min-width: 331px) {
      margin-top: 0;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .download-button-special {
    margin-top: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .demo-button-special {
    margin-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .demo-button-special {
    margin-left: 0;
  }
  .download-button-special {
    margin-left: 0;
  }
}

@media (max-width: 328px) {
  .demo-button-special {
    margin-left: 0;
  }
  .download-button-special {
    margin-left: 0;
  }
}

@media (min-width: 352px) and (max-width: 362px) {
  .download-button-special {
    margin-top: 10px;
  }
}

@media (min-width: 314px) and (max-width: 328px) {
  .demo-button-special {
    margin-top: 0;
  }
}

@media (max-width: 313px) {
  .demo-button-special {
    margin-top: 10px;
  }
}

.project-stars {
  font-size: 20px;
}

.rounded-hr {
  border-top: 3px solid #bbb;
  border-radius: 5px;
}

.left {
  display: flex;
  text-align: left;
  text-decoration: underline;
}

.contact-section {
  background-image: var(--section-background-color) !important;
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
  margin-top: 2em !important;
  height: 83.4vh;
}

@media (max-height: 600px) {
  .contact-section {
    height: initial;
  }
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-summary-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.contact-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.projects-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.text-project-curseforge {
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.contact-links {
  justify-content: left !important;
  text-align: left !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
  cursor: pointer !important;
}

.home-social-icons-picture,
.home-social-icons-picture-leetcode {
  margin-top: -1px !important;
}

.home-social-icons-picture-leetcode {
  margin-left: 1px !important;
}

@mixin social-icons-before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons::before {
  @include social-icons-before;
}

.contact-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: left !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.contact-social-icons::before {
  @include social-icons-before;
}

.footer-icon {
  color: white !important;
}

.footer-icon:hover {
  color: #68187a !important;
}

.home-contact-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-contact-header {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.contact-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.contact-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-copyright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copyright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.footer-end {
  text-align: center !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: var(--imp-text-color) !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding: 50px 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.image-card-view {
  box-shadow: 0 2px 2px 2px rgba(129, 72, 144, 0.561) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 20% !important;
  border-radius: 3px !important;
  margin-top: 0.5rem !important;
}

.image-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
}

.blog-card {
  padding: 50px 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0 !important;
  opacity: 0.8 !important;
  border-radius: 0 !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #623686 !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}

.btn:focus {
  outline-color: transparent !important;
  box-shadow: none !important;
}

.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

.project-card-footer {
  padding: 20px !important;
  text-align: left !important;
}

@media (max-width: 270px) {
  .project-card-footer {
    padding: 0 0 10px 0 !important;
  }
}

@mixin project-card-footer-label {
  font-size: 1.2em !important;
  font-weight: 500 !important;
  white-space: nowrap;
  color: white !important;
  background-color: white !important;
  border-radius: 5px !important;
  margin: 10px 7px 7px 10px !important;
  padding: 3px 10px !important;
  line-height: 2 !important;
}

@mixin project-card-footer-label-hover {
  background-color: #6d20c5d7 !important;
  transition: ease 0.5s !important;
  cursor: pointer !important;
}

.project-card-footer-label-first {
  @include project-card-footer-label;
  background-color: rgb(233, 79, 50) !important;
}

.project-card-footer-label-first:hover {
  @include project-card-footer-label-hover;
}

.project-card-footer-label-second {
  @include project-card-footer-label;
  background-color: rgb(102, 159, 100) !important;
}

.project-card-footer-label-second:hover {
  @include project-card-footer-label-hover;
  background-color: rgba(133, 62, 215, 0.84) !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

@mixin tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 2px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@mixin tech-icons-hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2px solid rgba(197, 115, 230, 0.883) !important;
  box-shadow: 6px 7px 8px 6px rgba(89, 4, 168, 0.183) !important;
  a {
    .tech-description {
      height: 100% !important;
      font-size: 0.4em !important;
      opacity: 1;
      font-weight: 500 !important;
      padding-top: 3px !important;
      color: rgba(255, 255, 255, 1) !important;
    }
  }
}

@mixin tech-icons-highlight {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 3px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 5px 6px 10px 7px rgba(101, 23, 169, 0.87) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@mixin tech-icons-highlight-hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 3px solid rgba(197, 115, 230, 0.883) !important;
  box-shadow: 6px 7px 6px 5px rgba(117, 14, 211, 0.86) !important;
  a {
    .tech-description {
      height: 100% !important;
      font-size: 0.4em !important;
      opacity: 1;
      font-weight: 500 !important;
      padding-top: 3px !important;
      color: rgba(255, 255, 255, 1) !important;
    }
  }
}

@mixin tech-icons-media {
  font-size: 0.3rem !important;
}

.tech-icons {
  @include tech-icons;
}

.tech-icons:hover {
  @include tech-icons-hover;
}

.tech-icons-highlight {
  @include tech-icons-highlight;
}

.tech-icons-highlight:hover {
  @include tech-icons-highlight-hover;
}

@media (max-width: 767px) {
  .tech-icons-angular {
    @include tech-icons-media;
  }
  .tech-icons-html {
    @include tech-icons-media;
  }
  .tech-icons-css {
    @include tech-icons-media;
  }
  .tech-icons-sass {
    @include tech-icons-media;
  }
  .tech-icons-postcss {
    @include tech-icons-media;
  }
  .tech-icons-tailwind {
    @include tech-icons-media;
  }
  .tech-icons-javascript {
    @include tech-icons-media;
  }
  .tech-icons-typescript {
    @include tech-icons-media;
  }
  .tech-icons-react {
    @include tech-icons-media;
  }
  .tech-icons-supabase {
    @include tech-icons-media;
  }
  .tech-icons-csharp {
    @include tech-icons-media;
  }
  .tech-icons-git {
    @include tech-icons-media;
  }
  .tech-icons-vscode {
    @include tech-icons-media;
  }
  .tech-icons-windows {
    @include tech-icons-media;
  }
  .tech-icons-office {
    @include tech-icons-media;
  }
  .tech-icons-ionic {
    @include tech-icons-media;
  }
}

.tech-icons-html {
  @include tech-icons;
}

.tech-icons-html:hover {
  @include tech-icons-hover;
  color: #e34f32 !important;
  border: 2px solid #e34f32 !important;
}

.tech-icons-css {
  @include tech-icons;
}

.tech-icons-css:hover {
  @include tech-icons-hover;
  color: #264de4 !important;
  border: 2px solid #264de4 !important;
}

.tech-icons-angular {
  @include tech-icons;
}

.tech-icons-angular:hover {
  @include tech-icons-hover;
  color: #c3002f !important;
  border: 2px solid #c3002f !important;
}

.tech-icons-ionic {
  @include tech-icons;
}

.tech-icons-ionic:hover {
  @include tech-icons-hover;
  color: #176bff !important;
  border: 2px solid #176bff !important;
}

.tech-icons-sass {
  @include tech-icons;
}

.tech-icons-sass:hover {
  @include tech-icons-hover;
  color: #cc6699 !important;
  border: 2px solid #cc6699 !important;
}

.tech-icons-postcss {
  @include tech-icons;
}

.tech-icons-postcss:hover {
  @include tech-icons-hover;
  color: #dd3a0a !important;
  border: 2px solid #dd3a0a !important;
}

.tech-icons-tailwind {
  @include tech-icons-highlight;
}

.tech-icons-tailwind:hover {
  @include tech-icons-highlight-hover;
  color: #38b2ac !important;
  border: 3px solid #38b2ac !important;
}

.tech-icons-javascript {
  @include tech-icons;
}

.tech-icons-javascript:hover {
  @include tech-icons-hover;
  color: rgb(240, 219, 79);
  border: 2px solid rgb(240, 219, 79) !important;
}

.tech-icons-typescript {
  @include tech-icons-highlight;
}

.tech-icons-typescript:hover {
  @include tech-icons-highlight-hover;
  color: rgb(0, 122, 205);
  border: 3px solid rgb(0, 122, 205) !important;
}

.tech-icons-react {
  @include tech-icons;
}

.tech-icons-react:hover {
  @include tech-icons-hover;
  color: rgb(90, 218, 253);
  border: 2px solid rgb(90, 218, 253) !important;
}

.tech-icons-supabase {
  @include tech-icons;
}

.tech-icons-supabase:hover {
  @include tech-icons-hover;
  color: #3bd08f !important;
  border: 2px solid #3bd08f !important;
}

.tech-icons-csharp {
  @include tech-icons;
}

.tech-icons-csharp:hover {
  @include tech-icons-hover;
  color: rgb(102, 32, 121);
  border: 2px solid rgb(102, 32, 121) !important;
}

.tech-icons-git {
  @include tech-icons;
}

.tech-icons-git:hover {
  @include tech-icons-hover;
  color: rgb(233, 79, 50);
  border: 2px solid rgb(233, 79, 50) !important;
}

.tech-icons-vscode {
  @include tech-icons;
}

.tech-icons-vscode:hover {
  @include tech-icons-hover;
  color: rgb(69, 173, 244);
  border: 2px solid rgb(69, 173, 244) !important;
}

.tech-icons-windows {
  @include tech-icons;
}

.tech-icons-windows:hover {
  @include tech-icons-hover;
  color: rgb(20, 122, 214);
  border: 2px solid rgb(20, 122, 214) !important;
}

.tech-icons-office {
  @include tech-icons;
}

.tech-icons-office:hover {
  @include tech-icons-hover;
  color: rgb(233, 60, 1);
  border: 2px solid rgb(233, 60, 1) !important;
}

.tech-description {
  font-size: 0.4rem !important;
  font-weight: 500 !important;
  padding-top: 3px !important;
  color: rgba(255, 255, 255, 0) !important;
  transition: all 0.4s ease 0s !important;
  width: 100% !important;
  height: 0.1rem;
  opacity: 0;
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-document {
  padding: 2px !important;
  border-radius: 7px !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}

@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  @media (min-width: 768px) {
    width: 7rem !important;
  }
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  @media (max-width: 767px) {
    width: 7rem !important;
  }
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.fork-btn-inner::after {
  display: none !important;
}

.fork-btn-text {
  font-size: 0.9em !important;
}

.field-of-interests-picture {
  width: 30% !important;
  height: 100% !important;
  vertical-align: center !important;
}

.basic-list {
  list-style: none !important;
}

.mod-description {
  margin: 0.3rem !important;
}

/*
.scroll-to-top-button {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  background-color: var(--imp-text-color) !important;
  color: #fff !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
}

.scroll-to-top-button:hover {
  background-color: var(--imp-text-color3) !important;
  cursor: pointer !important;
}*/

.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #5a327a;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 99999999;
  border: none;
}

.scroll-to-top-button:hover {
  background-color: var(--imp-text-color3);
  transition: linear 0.3s;
}

.discord-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.content.blur {
  filter: blur(5px);
  transition: all 0.5s;
}

.content {
  transition: all 0.5s;
}

.popup-discord-png {
  border-radius: 10px;
  height: 300px;
}

@keyframes star {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.star {
  animation-name: star;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.hits {
  height: 1.4em !important;
  width: 5.4em !important;
}

.negative-letter-spacing {
  letter-spacing: -0.04em !important;
}

.no-after:after {
  background: none !important;
}
